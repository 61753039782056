<template>

  <section id="encurso-tramo"> 

    <div v-if="selectedTramo.transporte_solicitud && selectedTramo.transporte_solicitud.length > 0" class="row">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Asiento</th>
              <th scope="col">Pasajero</th>
              <th scope="col" class="d-flex justify-content-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(solicitud, i) in selectedTramo.transporte_solicitud" :key="i">
              <td class="align-middle">
                <div class="d-flex justify-content-center">
                  <h3>{{ solicitud.transporte_asiento.nombre_asiento }}</h3>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center">

                  <img 
                  class="img-obj img-obj-60 img-obj-circle border me-3" 
                  :src="handleUserImage(solicitud.usuario.imagen_perfil)"
                  />
                  
                  <div>
                    <p class="text-secondary mb-0">{{ solicitud.usuario.primer_nombre }} {{ solicitud.usuario.apellido_paterno }}</p>
                    <span class="text-secondary font-size-14 me-1">{{ solicitud.usuario.correo }}</span>
                    <p class="text-black-50 lh-sm mb-0"><small>{{ dateFormatymd(solicitud.fecha_creacion) }}</small></p>
                  </div>
                </div>

                
                
              </td>
              <td class="align-middle">
                <div class="d-flex justify-content-center">

                  <div class="dropdown">
                    <button class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="javascript:" @click="openModalDetail()"><font-awesome-icon class="pe-2 color-main" icon="pencil"/> Editar</a></li>
                      <li><a class="dropdown-item" href="javascript:" @click="deleteSolicitud(solicitud)"><font-awesome-icon class="pe-2 color-main" icon="trash-alt"/> Eliminar</a></li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    
    <div v-if="showAlertStart" class="row">
      <div class="col-12">
        <div class="alert alert-info text-center mb-0" role="alert">
          <font-awesome-icon icon="circle-arrow-left"/>
          Selecciona un tramo para ver el detalle.
        </div>
      </div>
    </div>

    <div v-if="showAlertSelected" class="row">
      <div class="col-12">
        <div class="alert alert-warning text-center mb-0" role="alert">
          <font-awesome-icon icon="circle-info"/>
          Tramo seleccionado sin inscripciones.
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-detalle-inscripcion" 
      tabindex="-1" 
    >
      <div class="modal-dialog modal-lg">
        <EncursoDetalle 
          v-if="modal_detail"
          @close-modal-detail="closeModalDetail"
          @update-data="openModalDetail"
          @update="parentData = $event"
        />
      </div>
    </div>

  </section>

</template>

<script>
import EncursoDetalle from "./EncursoDetalle.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { 
    EncursoDetalle
  },

  data() {
    return {
      modal_detail: false,
      user_default_img: require("@/assets/img/user_default_img.svg")
    }
  },
  computed: {
    ...mapState("transporteModule", ["selectedTramo"]),

    showAlertStart() {
        return Object.keys(this.selectedTramo).length === 0 || Object.keys(this.selectedTramo).length === 0 && Object.keys(this.selectedTramo.transporte_solicitud).length === 0;
    },
    showAlertSelected() {
        return Object.keys(this.selectedTramo).length !== 0 && Object.keys(this.selectedTramo.transporte_solicitud).length === 0;
    }
  },

  methods: {
    ...mapActions("transporteModule", ["setSelectedSolicitudAction", "deleteSolicitudAction"]),

    setSelectedTramoData(inscripcion) {
      this.setSelectedSolicitudAction(inscripcion);
    },
    
    closeModalDetail() {
      this.modal_detail = false;
    },

    openModalDetail() {
      this.modal_detail = true;
    },

    async deleteSolicitud(solicitud) {
      const data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idSolicitud: solicitud.id_solicitud,
        idUsuario: solicitud.usuario.id_usuario,
      };
      await this.deleteSolicitudAction(data);
      this.toastSolicitudDeleteSuccess();
    },

    handleUserImage(img) {
      return img ? img : this.user_default_img;
    },

    toastSolicitudDeleteSuccess() {
      this.$toast.open({
        message: 'La solicitud ha sido eliminada correctamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      }); 
    }
  },
};
</script>
