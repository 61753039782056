<script>
import DistribucionAsientos from "../DistribucionAsientos.vue"
export default {
	components: { DistribucionAsientos },
	props: {
		tramoAsientos: {
			type: Array,
			default() {
				return [];
			},
			required: true,
		}
	}
}
</script>
<template>
	<div 
		class="modal fade" 
		id="modal-asientos-tomados" 
		tabindex="-1" 
		aria-hidden="true"
	>
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title text-secondary">
						<strong>Asientos reservados</strong>
					</h5>
					<button 
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<font-awesome-icon icon="xmark"/>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 col-lg-5">
							<h6>Vista general</h6>

							<DistribucionAsientos :asientos="tramoAsientos" />

						</div>
						<div class="col-12 col-lg-7">
							<h6>Inscritos/disponibles</h6>
							<div class="card card-custom card-custom-scroll">
								<div class="card-body">
									<ul class="list-group list-group-flush">
										<li 
											v-for="item in tramoAsientos" :key="`lista-${item.id_asiento}`"
											class="list-group-item"
										>
										<div class="d-flex align-items-center">
											<div class="flex-shrink-0" style="width: 40px;">
												<h1 class="font-700 text-secondary">
													{{ item.nombre_asiento }}
												</h1>
											</div>
											<div class="flex-grow-1 ms-3">
												<div class="d-flex align-items-center">
													<template v-if="item.disponible">
														<div class="flex-shrink-0">
															<span 
																class="d-flex justify-content-center align-items-center h3 text-secondary text-opacity-50"
																style="width:48px; height:48px;"
															>
																<i class="fa-solid fa-couch"></i>
															</span>
														</div>
														<div class="flex-grow-1 ms-3">
															<p class="color-green mb-0">
																Disponible
															</p>
														</div>
													</template>
													<template v-else>
														<div class="flex-shrink-0">
															<img 
																width="48"
																height="48"
																class="img-object-cover rounded-circle"
																:src="item.solicitud.usuario.imagen_perfil"
															>
														</div>
														<div class="flex-grow-1 ms-3">
															<p class="text-secondary mb-0">
																{{ item.solicitud.usuario.primer_nombre }}
																{{ item.solicitud.usuario.apellido_paterno }}
															</p>
														</div>
													</template>
												</div>
											</div>
										</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
