<template>

<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">
        <font-awesome-icon 
          icon="circle-user" 
          class="color-secondary me-1"
        />
        <strong>{{ selectedSolicitud.usuario.primer_nombre }}</strong> 
        {{ selectedSolicitud.usuario.apellido_paterno + ' ' + selectedSolicitud.usuario.apellido_materno }}<br>
        <span class="badge badge-custom border text-secondary font-size-14 me-1">
          <font-awesome-icon 
            icon="envelope" 
            class="color-light"
          />
          {{ selectedSolicitud.usuario.correo }}
        </span>
      </h5>
      <button 
        type="button" 
        class="modal-custom-close"
        data-bs-dismiss="modal" 
        aria-label="Close">
        <font-awesome-icon icon="xmark"/>
      </button>
    </div>
    <div class="modal-body">

      <h5 class="font-main-bold text-secondary pb-2 mb-2 border-bottom">
        Detalles del usuario
      </h5>

      <div class="row">
        <div class="col-12">
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="address-card" 
                  class="color-secondary"
                />
                RUT
              </span>
              <span>
                {{ selectedSolicitud.usuario.rut_usuario + '-' + selectedSolicitud.usuario.dv_usuario }}
              </span>
            </li>
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="tag" 
                  class="color-secondary"
                />
                Cargo
              </span>
              <span>
                {{ selectedSolicitud.usuario.cargo[0].nombre_cargo }}
              </span>
            </li>
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="user-tie" 
                  class="color-secondary"
                />
                Gerencia
              </span>
              <span>
                {{ selectedSolicitud.usuario.gerencia[0].nombre_gerencia }}
              </span>
            </li>
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="square-phone-flip" 
                  class="color-secondary"
                />
                Teléfono
              </span>
              <span>
                {{ selectedSolicitud.usuario.telefono_movil }}
              </span>
            </li>
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="plane-up" 
                  class="color-secondary"
                />
                Tipo Viaje
              </span>
              <span>
                {{ selectedSolicitud.transporte_ida_vuelta.nombre_idea_vuelta == 'Ida'? 'Solo ida' : 'Solo vuelta' }}
              </span>
            </li>
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="shop" 
                  class="color-secondary"
                />
                Sucursal
              </span>
              <span>
                {{ selectedSolicitud.usuario.sucursal[0].nombre_sucursal }}
              </span>
            </li>
            <li class="list-group-item text-secondary d-flex justify-content-between align-items-center">
              <span>
                <font-awesome-icon 
                  icon="industry" 
                  class="color-secondary"
                />
                Empresa
              </span>
              <span>
                {{ selectedSolicitud.usuario.empresa[0].nombre_empresa }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h5 class="font-main-bold text-secondary pb-2 mb-2 border-bottom">
            Actualizar inscripción
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <label class="text-secondary">Turno</label>
          <v-select 
            class="selvue-custom"
            :placeholder="'Selecciona un turno'" 
            :options="turnos_list" 
            v-model="editData.turno"
            label="nombre_turno"
            :clearable="false"
            :searchable="false"
          >
            <span slot="no-options"></span>
          </v-select>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <label class="text-secondary">Team</label>
          <v-select 
            class="selvue-custom"
            :placeholder="'Selecciona un team'" 
            :options="teams_list" 
            label="nombre_team"
            v-model="editData.team"
            :clearable="false"
            :searchable="false"
          >
            <span slot="no-options"></span>
          </v-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label class="text-secondary">Asiento</label>
          <div class="accordion accordion-custom">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button 
                  class="accordion-button collapsed" 
                  type="button"
                  data-bs-toggle="collapse" 
                  data-bs-target="#collapse-detalle-inscripcion-asientos" 
                  aria-expanded="false"
                >
                <span class="d-flex justify-content-between w-100">
                  <span>
                    <i class="fa-solid fa-couch color-secondary pe-2"></i>
                    Asiento actual
                  </span>
                    <span 
                      v-show="selectedSolicitud.transporte_asiento.nombre_asiento !== ''"
                      class="badge bg-success rounded-pill me-2">
                      {{ editData.asiento.nombre_asiento }}
                    </span>
                </span>
                </button>
              </h2>
              <div id="collapse-detalle-inscripcion-asientos" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div class="row">
                    <div class="col-12 form-nums">  
                      <DistribucionAsientos 
                        :asientos="selectedTramo.asientos" 
                        :editable="true"
                        @clickAsiento="selectAsiento" 
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="acceptStatus"
      />
      <Spinner v-if="show_spinner"/>
    </div>
    <div class="modal-footer">
      <button 
        type="button" 
        ref="closeModal"
        @click="closeModalDetalle()"
        class="btn btn-custom-color-white rounded-pill mw-100" 
        data-bs-dismiss="modal">
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>
      <button 
        @click="saveChanges()"
        type="button" 
        class="btn btn-custom-color-blue rounded-pill mw-100">
        Guardar
      </button>
    </div>
  </div>

</template>

<script>
import { mapState, mapActions } from "vuex";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
import DistribucionAsientos from "../DistribucionAsientos.vue"

export default {
  components: { 
    Status, 
    Spinner,
    DistribucionAsientos
  },

  data() {
    return {
      show_spinner: false,
      modal_status_msg: "",
      modal_status: false,
      open_modal_status: false,

      turno: {},
      team: {},

      editData: {
        asiento: "",
        team: "",
        turno: ""
      },
      editBool: false
    }
  },

  props: {
    updateData: {
      type: Function
    },
  },

  computed: {
    ...mapState("transporteModule", ["selectedTramo", "selectedSolicitud", "turnos_list", "teams_list"]),
  },

  methods: {
    ...mapActions("transporteModule", ["editSolicitudTransporteAdmin"]),

    closeModalDetalle() {
      this.$refs.closeModal.click();
      this.$emit("close-modal-detail");
    },

    activateEditMode() {
      if (!this.editBool) {
        this.editBool = true;
      } else {
        this.saveChanges();
      }
    },

    //FF
    selectAsiento(selected_asiento) {
      let id_actual = this.selectedSolicitud.transporte_asiento.id_asiento;
      if(selected_asiento.disponible) {
        this.editData.asiento = selected_asiento;
        this.selectedTramo.asientos.forEach(element => {
          if (this.editData.asiento.id_asiento === element.id_asiento) {
            element.selected = true;
          } else {
            element.selected = false;
            if(id_actual === element.id_asiento) {
              element.disponible = true;
            }
          }
        });
      }

      
    },

    selectTurno(id_turno) {
      this.editData.turno = id_turno;
    },

    selectTeam(id_team) {
      this.editData.team = id_team;
    },

    async saveChanges() {
      const payload = {
        idAsiento: this.editData.asiento.id_asiento,
        idTurno: this.editData.turno.id_turno,
        idTeam: this.editData.team.id_team,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idCreador: this.$ls.get("user").id_usuario,
        idTramo: this.selectedTramo.id_tramo,
        idSolicitud: this.selectedSolicitud.id_solicitud,
        idUsuario: this.selectedSolicitud.usuario.id_usuario,
      };
      this.show_spinner = true;
      const editedInscripcion = await this.editSolicitudTransporteAdmin(payload);
      this.show_spinner = false;
      if (editedInscripcion) {
        this.toastSolicitudEditSuccess();
        this.closeModalDetalle();
        //FF
        this.selectedSolicitud.transporte_asiento = this.editData.asiento;
      } else {
        this.modal_status = false;
        this.modal_status_msg = "Ha ocurrido un problema en la edición de la inscripción. Por favor inténtalo de nuevo.";
        this.open_modal_status = true;
      }
    },

    statusAsiento(asiento) {
      if (asiento.selected) {
        return "selected";
      }
      if (!asiento.disponible) {
        return "inactive";
      }
    },

    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },

    toastSolicitudEditSuccess() {
      this.$toast.open({
        message: 'La inscripción ha sido actualizada correctamente.',
        type: 'success',
        duration: 6000,
        position: 'top-right'
      });
    }
  },

  watch: {

    //FF
    selectedSolicitud: {
      immediate: true,
			handler(value) {
        let blocks = [];
        //Guarda el asiento de la incripción actual
        let id_actual = this.selectedSolicitud.transporte_asiento.id_asiento;
        //Guardar los asientos bloquedados de la planificación
        this.selectedTramo.transporte_solicitud.forEach(element => {
          blocks.push(element.transporte_asiento.id_asiento);
        });
        if (this.selectedTramo.transporte_solicitud.length > 0) {
          this.editData.asiento = value.transporte_asiento;
          this.editData.team = value.usuario.team;
          this.editData.turno = value.usuario.turno[0];
          this.selectedTramo.asientos.forEach(element => { 
            element.selected = false;
            //Marca los asientos bloqueados de la planificación
            blocks.forEach(block => {
              if(block === element.id_asiento) {
                element.disponible = false;
              }
            });
            //Marca el asiento actual de la inscrpción
            if(id_actual === element.id_asiento) {
              element.selected = true;
            }
        });
        }
			}    
    },
  }
};
</script>
